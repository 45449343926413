.adminTriviaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 4%;

  box-shadow: 3px 3px 10px #CCC;
  border-radius: 10px;
}

.adminTriviaContainer h2 {
  font-size: 30px;
  color: #888;
  margin: 10px auto;
}

.adminTriviaContainer .inputTime {
  margin: auto;
  padding: 10px 20px;

  color: var(--color-dark-blue);
  font-size: 2vw;
  text-align: center;

  border: 1px solid #888;
  border-radius: 10px;
  outline: none;
}

.adminTriviaContainer .inputTime::placeholder {
  margin: auto;
  padding: 10px 20px;

  color: #999;
  font-size: 1.5vw;
  text-align: center;
}

.adminTriviaContainer h4 {
  font-size: 20px;
  color: #888;
  margin: 10px auto;
}

.adminTriviaContainer .adminTriviaOptions {
  display: flex;
  flex-direction: row;
}

.adminTriviaContainer .adminTriviaPlus {
  width: 40px;
  min-width: 40px;
  height: 40px;

  border-radius: 50vw;
  border: 0px;
  outline: none;

  background-color: #00677F;
  color: white;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.adminTriviaContainer .adminTriviaLess {
  width: 40px;
  min-width: 40px;
  height: 40px;

  border-radius: 50vw;
  border: 0px;
  outline: none;

  background-color: #FF066C;
  color: white;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------------------------------------- */

.adminTriviaContainer .adminTriviaCards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  background-color: rgba(255, 255, 255, 0.473);
  padding: 10px;
  margin: 1vh;

  width: 100%;

  border-top: 1px solid #888;
}

.adminTriviaContainer .adminTriviaCard {
  width: 300px;
  min-height: 350px;

  margin: 10px;
  padding: 20px;

  box-shadow: 3px 3px 10px #AAA;
  border-radius: 10px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.adminTriviaContainer .adminTriviaCard input {
  width: 70%;

  margin: 10px auto;
  padding: 10px 20px;

  border: none;
  outline: none;
  border-radius: 10px;

  box-shadow: 3px 3px 10px #888;
}

.adminTriviaContainer .adminTriviaCard h4 {
  font-size: 16px;
}

.adminTriviaContainer .adminTriviaCard .adminTriviaCardOptions {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #888;
}

.adminTriviaContainer .adminTriviaCard .answersCont {
  width: 90%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.adminTriviaContainer .adminTriviaCard select {
  width: 90%;

  margin: 10px auto;
  padding: 5px 10px;

  border: 1px solid #DDD;
  outline: none;
}

/* --------------------------- */

.valueOfTime {
  width: 40%;

  margin: 10px auto;
  padding: 10px 20px;

  border: none;
  outline: none;
  border-radius: 10px;

  text-align: center;

  box-shadow: 3px 3px 10px #888;
}

.adminTriviaSend {
  width: 100px;
  min-width: 40px;
  height: 40px;

  border-radius: 10px;
  border: 0px;
  outline: none;

  background-color: #00677F;
  color: white;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.adminTriviaCancel {
  width: 100px;
  min-width: 40px;
  height: 40px;

  border-radius: 10px;
  border: 0px;
  outline: none;

  background-color: #FF066C;
  color: white;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* ============================================== */

.separator {
  width: 90%;
  height: 1px;

  margin: auto;

  background-color: #888;
}

.chartContainer {
  display: flex;

  margin: auto;
}

.chartContainer canvas {
  margin: auto;
}

/* ================================================== */

.adminTriviaCreated {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.adminTriviaCreatedCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.473);
  padding: 3vh;
  border-radius: 40px;
  word-wrap: break-word;
  margin: 1vh;
}

.adminTriviaCreatedButton1 {
  width: 80px;
  min-width: 40px;
  height: 40px;

  border-radius: 10px;
  border: 0px;
  outline: none;

  background-color: #00677F;
  color: white;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.adminTriviaCreatedButton2 {
  width: 80px;
  min-width: 40px;
  height: 40px;

  border-radius: 10px;
  border: 0px;
  outline: none;

  background-color: #FF066C;
  color: white;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.winner {
  display: flex;
  flex-direction: row;
}

/* ================================ */

.winnersCont {
  width: 100%;

  display: flex;
  flex-flow: column;

  margin: auto;
}

.winnersCont h1 {
  text-align: center;
  margin: 10px auto;
}

.winnersCont .winnerCont {
  width: 100%;

  margin: auto;

  display: flex;
}

.winnersCont .winnerCont p {
  margin: 10px 20px auto auto;
}

.winnersCont .winnerCont .name {
  font-weight: 600;
}

.winnersCont .winnerCont .score {
  color: #FF066C;
  font-weight: 600;
}
div[class^="ChatContainer"] {
  position: relative;

  width: 100%;
  height: 80%;

  margin: 0px auto 20px auto;
  padding: 0;
}

.ChatContainer-chat {
  display: flex;
  flex-flow: column;
}
.ChatContainer-chat .welcome {
  width: 90%;

  margin: 0 auto;
  padding: 5%;

  display: flex;
  flex-flow: column;

  background-color: #DADADA;
}

.ChatContainer-chat .welcome p {
  color: #878787;
  font-family: Myriad-Pro-Regular;
  font-size: .8vw;

  margin: auto;
  width: 100%;
}

.ChatContainer-agenda {
  display: none;
}
.ChatContainer-game {
  display: none;
}

div[class^="ChatContainer"] .chat {
  position: relative;

  width: 100%;
  height: 100%;

  margin: auto;
  padding: 0px;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00AFAA transparent;
}

/* width and height */
div[class^="ChatContainer"] .chat::-webkit-scrollbar {
  width: 7px; height: 6px;
}

/* Track */
div[class^="ChatContainer"] .chat::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
div[class^="ChatContainer"] .chat::-webkit-scrollbar-thumb {
  background: #00AFAA;
  border-radius: 10px;
}

/* Handle on hover */
div[class^="ChatContainer"] .chat::-webkit-scrollbar-thumb:hover {
  background: #206068;
}

div[class^="ChatContainer"] .chat .chatBuffer {
  width: 100%;
  height: auto;

  margin: 0 auto auto auto;

  background-color: #FFF;
}

/* ==================================================== */

div[class^="ChatContainer"] .chat .chatBuffer .chatCard {
  width: 75%;

  padding: 2.5%;
  margin: 2% auto 2% 2%;

  background-color: #DADADA;
  border: none;
  border-radius: 10px 10px 10px 0px;

  text-align: start;
  color: #555;
  font-weight: 600;
  font-size: 1vw;
  font-family: Myriad-Pro-Regular;

  overflow: auto;

  display: flex;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatCard .avatarCont,
div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .avatarCont {
  width: 40px;
  height: 40px;
  min-width: 40px;

  margin: auto 10px auto 0px;

  background-color: #FCC43D;
  border-radius: 100px;

  color: #FFF;
  font-family: Myriad-Pro-Light;
  font-size: 1.2vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .avatarCont {
  background-color: #FFF;
  color: #00AFAA;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatCard .texts,
div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .texts {
  width: 100%;
  display: flex;
  flex-flow: column;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatUsername {
  margin: 0px;
  font-size: .8vw;
  font-family: Myriad-Pro-Bold;
  text-transform: lowercase;
  text-transform: capitalize;
  color: #878787;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatUsername .time,
div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatUsername .time {
  color: #999;
  font-family: Myriad-Pro-Light;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatUsername .time {
  color: #FFF;
  font-family: Myriad-Pro-Light;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard {
  width: 75%;

  margin: 2% 1% 2% auto;
  padding: 2.5%;

  border: none;
  border-radius: 10px 10px 0px 10px;
  background-color: #00AFAA;

  color: #FFF;
  font-size: 1vw;
  text-align: start;
  font-family: Myriad-Pro-Regular;

  overflow: auto;

  display: flex;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatUsername {
  width: 100%;
  margin: auto;
  font-size: .8vw;
  font-family: Myriad-Pro-Bold;
  text-transform: lowercase;
  text-transform: capitalize;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatMessage {
  margin: 10px auto 5px auto;
  font-weight: 300;
  font-size: .9vw;
  text-align: start;
  width: 100%;
}

div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatMessage {
  width: 100%;
  margin: 10px auto 5px auto;
  font-weight: 300;
  font-size: .9vw;
  text-align: start;
}

/* width and height */
div[class^="ChatContainer"] .chat .chatBuffer *::-webkit-scrollbar {
  width: 10px; height: 6px;
}

/* Track */
div[class^="ChatContainer"] .chat .chatBuffer *::-webkit-scrollbar-track {
  background: #FFF;
  border-radius: 10px;
}

/* Handle */
div[class^="ChatContainer"] .chat .chatBuffer *::-webkit-scrollbar-thumb {
  background: #00AFAA;
  border-radius: 10px;
}

/* Handle on hover */
div[class^="ChatContainer"] .chat .chatBuffer *::-webkit-scrollbar-thumb:hover {
  background: #206068;
}

/* =============================================== */

div[class^="ChatContainer"] .chatForm {
  width: 90%;

  margin: 20px auto 0 auto;

  display: flex;

  background-color: #EDEDED;
  border-radius: 200px;

  z-index: 100;
}

div[class^="ChatContainer"] .chatForm .chatInput {
  width: 75%;

  margin: auto 3%;
  padding: 5px 1vw;

  border: none;
  outline: none;

  text-align: start;
  font-size: 1vw;
  color: #111;
  font-family: Myriad-Pro-Regular;

  background-color: transparent;

  z-index: 10;
}

div[class^="ChatContainer"] .chatForm .chatInput::placeholder {
  color: #999;
}

div[class^="ChatContainer"] .chatForm .chatInput:disabled {
  background-color: transparent;
}

div[class^="ChatContainer"] .chatForm .chatInput:disabled::placeholder {
  color: #00AFAA;
}

div[class^="ChatContainer"] .chatForm .chatInputButton {
  z-index: 10;

  width: 2vw;
  height: 2vw;
  min-width: 2vw;

  margin: auto 2% auto auto;

  border: none;
  border-radius: 200px;
  color: #FFF;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00AFAA;

  cursor: pointer;
}

div[class^="ChatContainer"] .chatForm .chatInputButton svg {
  width: 90%;
  height: 90%;
}



/* ========================================== */
/* ========================================== */
/* ========================================== */
/* ========================================== */


@media (max-width: 1024px) {
  .ChatContainer-chat .welcome p {
    font-size: 1.3vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard {
    font-size: 1.5vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .avatarCont,
  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .avatarCont {
    width: 3vw;
    height: 3vw;
    min-width: 3vw;
    margin: auto 10px auto 0px;
    font-size: 1.8vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatUsername {
    font-size: 1.3vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatMessage {
    font-size: 1.3vw;
  }

  /* -------------------------------------------------------- */

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard {
    font-size: 1.3vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 1.3vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 1.3vw;
  }

  /* =============================================== */
  div[class^="ChatContainer"] .chatForm .chatInput {
    font-size: 1.5vw;
  }

  div[class^="ChatContainer"] .chatForm .chatInputButton {
    width: 2.5vw;
    height: 2.5vw;
    min-width: 2.5vw;
  }

  div[class^="ChatContainer"] .chatForm .chatInputButton svg {
    width: 100%;
    height: 100%;
  }
}

/* ============================================== */

@media (max-width: 768px) {
  .ChatContainer-chat .welcome p {
    font-size: 2vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard,
  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard {
    width: 85%;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .avatarCont,
  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .avatarCont {
    width: 4vw;
    height: 4vw;
    min-width: 4vw;
    margin: auto 10px auto 0px;
    font-size: 2.5vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatUsername {
    font-size: 1.9vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatMessage {
    font-size: 2.1vw;
  }

  /* -------------------------------------------------------- */

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 1.9vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 2.1vw;
  }

  /* =============================================== */
  div[class^="ChatContainer"] .chatForm .chatInput {
    font-size: 2.1vw;
    padding: 5px 0.5vw;
  }

  div[class^="ChatContainer"] .chatForm .chatInputButton {
    width: 3.5vw;
    height: 3.5vw;
    min-width: 3.5vw;
  }
}

@media (max-width: 640px) {
  .ChatContainer-chat .welcome p {
    font-size: 2.5vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard,
  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard {
    width: 85%;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .avatarCont,
  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .avatarCont {
    width: 5vw;
    height: 5vw;
    min-width: 5vw;
    margin: auto 10px auto 0px;
    font-size: 3.5vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatUsername {
    font-size: 2.4vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatMessage {
    font-size: 2.9vw;
  }

  /* -------------------------------------------------------- */

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 2.4vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 2.9vw;
  }

  /* =============================================== */
  div[class^="ChatContainer"] .chatForm .chatInput {
    font-size: 2.7vw;
    padding: 5px 0.5vw;
  }

  div[class^="ChatContainer"] .chatForm .chatInputButton {
    width: 4vw;
    height: 4vw;
    min-width: 4vw;
  }
}

@media (max-width: 380px) {
  .ChatContainer-chat .welcome p {
    font-size: 3.5vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard,
  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard {
    width: 90%;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .avatarCont,
  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .avatarCont {
    width: 8vw;
    height: 8vw;
    min-width: 8vw;
    margin: auto 10px auto 0px;
    font-size: 6vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatUsername {
    font-size: 3.3vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatCard .chatMessage {
    font-size: 4.5vw;
  }

  /* -------------------------------------------------------- */

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatUsername {
    font-size: 3.3vw;
  }

  div[class^="ChatContainer"] .chat .chatBuffer .chatOwnCard .chatMessage {
    font-size: 4vw;
  }

  /* =============================================== */

  div[class^="ChatContainer"] .chatForm {
    width: 100%;
  }

  div[class^="ChatContainer"] .chatForm .chatInput {
    font-size: 4vw;
    padding: 5px 0.5vw;

    width: 100%;
  }

  div[class^="ChatContainer"] .chatForm .chatInputButton {
    width: 6.5vw;
    height: 6.5vw;
    min-width: 6.5vw;
  }
}
.registerCont .formContainer {
  width: 95%;

  display: flex;

  margin: auto auto 5px auto;
}

.registerCont .formContainer .form {
  position: relative;

  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.registerCont .formContainer .loanding {
  width: 10vw;
  height: calc(10vw / 1.02652);

  margin: auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-image: url('../../../img/login/loanding.png');

  z-index: 10;

  animation: rotate infinite 1.5s linear;
  animation-play-state: running;
  -moz-animation-play-state:running;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.registerCont .inputContainer {
  position: relative;
  width: 45%;

  margin: 10px;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.registerCont .formContainer .form input {
  width: 85%;

  margin: auto ;
  padding: 5px calc(5% - 1px);

  border-radius: 10px;
  border: 1px solid #00AFAA;
  outline: none;

  text-align: start;
  font-size: 1.3vw;
  color: #006671;
  font-weight: 300;
  font-family: Myriad-Pro-Cond-Italic;

  z-index: 10;
}

.registerCont .formContainer .form input::placeholder {
  color: #999;
}

/* ======================================= */

.redirect {
  color: #00AFAA;
  font-size: 1vw;
  line-height: 1vw;

  margin: auto 20px auto auto;

  cursor: pointer;
}

.registerCont .formContainer .form .btnSubmit {
  background-color: #FCC43D;
  border: none;
  outline: none;
  border-radius: 100px;

  color: #FFF;
  font-size: 1.2vw;
  font-weight: 100;
  font-family: Myriad-Pro-Bold;

  margin: 10px 5% 10px 10px;
  padding: 5px 5%;

  cursor: pointer;
}

.registerCont .formContainer .form .btnSubmit:disabled {
  background-color: #AAA;
  border: none;
  outline: none;
  border-radius: 100px;

  color: #FFF;
  font-size: 1.2vw;
  font-weight: 100;
  font-family: Myriad-Pro-Bold;

  margin: 10px 5% 10px 10px;
  padding: 5px 5%;

  cursor: not-allowed;
}

/* ============================================= */
/* ============================================= */

.registerCont .formContainer .errorMsg {
  width: 90%;

  margin: auto;
  padding: 20px;

  background-color: #FFF5;
  box-shadow: 3px 3px 5px #AAA;
  border-radius: 20px;

  display: flex;
  flex-flow: column;
}

.registerCont .formContainer .errorMsg h2 {
  margin: auto;

  text-align: center;
  font-size: 1.5vw;
  color: #00AFAA;
}

.registerCont .formContainer .errorMsg .back {
  padding: 5px 10%;
  margin: 20px auto;

  background-color: #00AFAA;
  border-radius: 100px;
  border: none;
  outline: none;

  color: #FFF;
  font-weight: 600;
  font-size: 2vw;

  cursor: pointer;
}

/* ====================================== */
/* ====================================== */
/* ====================================== */


@media (max-width: 1024px) {
  .registerCont .formContainer {
    width: 90%;

    margin: 10px auto;
  }

  .registerCont .formContainer .form input {
    width: 90%;
    font-size: 2vw;
  }

  .redirect {
    font-size: 2vw;
    line-height: 2vw;
  }

  .registerCont .formContainer .form .btnSubmit {
    font-size: 2vw;
  }
}

@media (max-width: 640px) {
  .registerCont .formContainer {
    width: 90%;

    margin: 10px auto;
  }

  .registerCont .formContainer .form input {
    font-size: 3.3vw;
    line-height: 3.3vw;
  }

  .redirect {
    font-size: 3vw;
    line-height: 3vw;
  }

  .registerCont .formContainer .form .btnSubmit {
    font-size: 3vw;
  }
}

@media (max-width: 480px) {
  .registerCont .formContainer {
    width: 90%;

    margin: 1% auto;
  }

  .registerCont .inputContainer {
    width: 46%;

    margin: 2%;
  }

  .registerCont .formContainer .form input {
    font-size: 3.8vw;
    line-height: 3.8vw;

    margin: 0;
  }

  .redirect {
    font-size: 4vw;
    line-height: 4vw;
  }

  .registerCont .formContainer .form .btnSubmit {
    font-size: 4vw;
  }
}

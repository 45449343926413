.registerCont {
  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../img/bgRegister.png');
}

.registerCont .infoCont {
  width: 45%;
  height: 100%;

  background-color: #FFFB;

  margin: auto 0 auto auto;
  display: flex;
  flex-flow: column;

  scrollbar-width: thin;
  scrollbar-color: #00AFAA transparent;

  overflow-x: hidden;
  overflow-y: auto;
}

.registerCont .infoCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}

.registerCont .infoCont::-webkit-scrollbar-track {
  background: transparent;
}

.registerCont .infoCont::-webkit-scrollbar-thumb {
  background: #00AFAA;
}

.registerCont .infoCont::-webkit-scrollbar-thumb:hover {
  background: #106461;
}

/* ------------------------------------ */

.registerCont .infoCont .eventName {
  width: 55%;

  background-color: #FFFD;
  border-radius: 10px;

  margin: 60px auto 30px auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.registerCont .infoCont .eventName p {
  margin: 5px;
  font-size: 4vw;
  color: #878787;
  font-weight: 800;
  font-family: Myriad-Pro-black-cond;
}

.registerCont .infoCont .eventName span {
  font-size: 1.6vw;
  line-height: 1.6VW;
  color: #00AFAA;
  font-weight: 600;
  font-family: Myriad-Pro-black-cond;

  width: 60%;

  margin: 5px;
}

/* ------------------------------------------ */

.registerCont .infoCont .logos {
  width: 100%;

  margin: auto;

  display: flex;
}

.registerCont .infoCont .logos div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.registerCont .infoCont .logos .logoIdime {
  width: 12vw;
  height: calc(12vw / 3.5103);
  background-image: url('../../img/cover/logoIdime_1.png');

  margin: auto 5% auto auto;
}

.registerCont .infoCont .logos .logoAcreditacion {
  width: 7vw;
  height: calc(7vw / 1.0696);
  background-image: url('../../img/cover/logoOnac.png');

  margin: auto auto auto 5%;
}

/* ---------------------------------------- */

.registerCont .infoCont .infoAcredit {
  width: 100%;

  margin: auto;

  background-color: #9B9A92;
}

.registerCont .infoCont .infoAcredit p {
  width: 70%;

  margin: 5px auto;

  color: #FFF;
  text-align: center;
  font-size: .9vw;
  font-family: Myriad-Pro-Light;
}

/* --------------------------------- */

.registerCont .infoCont .social {
  width: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  margin: auto;
}

.registerCont .infoCont .social .media {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;

  width: 100%;
}

.registerCont .infoCont .social .media a {
  text-decoration: none;
}

.registerCont .infoCont .social .media p {
  margin: auto 10px;

  color: #878787;
  font-size: 1vw;
  font-family: Myriad-Pro-Light;
}

.registerCont .infoCont .social .media p:hover,
.registerCont .infoCont .social .media .socialMedia:hover {
  color: #00AFAA;
}

.registerCont .infoCont .social .media .socialMedia {
  margin: auto 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.registerCont .infoCont .social .media .socialMedia .icon {
  width: 1vw;
  height: 1vw;

  margin: auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.registerCont .infoCont .social .media .socialMedia .face {
  background-image: url('../../img/cover/faceIcon.png');
}

.registerCont .infoCont .social .media .socialMedia .twitter {
  background-image: url('../../img/cover/twitterIcon.png');
}

.registerCont .infoCont .social .media .socialMedia .linkedin {
  background-image: url('../../img/cover/linkedinIcon.png');
}

.registerCont .infoCont .social .media .socialMedia p {
  margin: auto 2px;
}

.registerCont .infoCont .social .subTitle {
  color: #878787;
  font-size: 1.1vw;
  font-family: Myriad-Pro-Light;

  margin: 10px auto;
}

/* ====================================== */
/* ====================================== */
/* ====================================== */

@media screen and (max-width: 1024px) {
  .registerCont .infoCont {
    width: 55%;
  }

  /* ---------------------------------------------- */

  .registerCont .infoCont .eventName {
    width: 65%;
  }

  .registerCont .infoCont .eventName p {
    font-size: 5.5vw;
  }

  .registerCont .infoCont .eventName span {
    font-size: 2.1vw;
    line-height: 2.1VW;
  }

  /* ----------------------------------------------------- */

  .registerCont .infoCont .logos .logoIdime {
    width: 14vw;
    height: calc(14vw / 3.5103);
  }

  .registerCont .infoCont .logos .logoAcreditacion {
    width: 10vw;
    height: calc(10vw / 1.0696);
  }

  /* ------------------------------------------------------- */

  .registerCont .infoCont .infoAcredit p {
    width: 90%;
    font-size: 1.7vw;
  }

  /* ------------------------------------------------------ */

  .registerCont .infoCont .social .media p {
    font-size: 1.8vw;
  }

  .registerCont .infoCont .social .media .socialMedia .icon {
    width: 1.8vw;
    height: 1.8vw;
  }

  .registerCont .infoCont .social .subTitle {
    font-size: 2vw;
  }
}

/* ================================================================================================= */

@media screen and (max-width: 768px) {
  .registerCont {
    background-position: left;
  }

  .registerCont .infoCont {
    width: 90%;
    height: 90%;

    background-color: #FFFD;

    margin: auto;
  }

  /* ---------------------------------------------- */

  .registerCont .infoCont .eventName {
    width: 60%;

    margin: 2% auto;
  }

  .registerCont .infoCont .eventName p {
    font-size: 9vw;
  }

  .registerCont .infoCont .eventName span {
    font-size: 3.4vw;
    line-height: 3.4VW;
  }

  /* ----------------------------------------------------- */

  .registerCont .infoCont .logos .logoIdime {
    width: 18vw;
    height: calc(18vw / 3.5103);
  }

  .registerCont .infoCont .logos .logoAcreditacion {
    width: 14vw;
    height: calc(14vw / 1.0696);
  }

  /* ------------------------------------------------------- */

  .registerCont .infoCont .infoAcredit p {
    width: 90%;
    font-size: 2vw;
  }

  /* ------------------------------------------------------ */

  .registerCont .infoCont .social .media p {
    font-size: 2.5vw;
  }

  .registerCont .infoCont .social .media .socialMedia .icon {
    width: 2.5vw;
    height: 2.5vw;
  }

  .registerCont .infoCont .social .subTitle {
    font-size: 3vw;
  }
}

@media screen and (max-width: 480px) {

  .registerCont .infoCont .eventName {
    width: 70%;

    margin: 5% auto;
  }

  .registerCont .infoCont .eventName p {
    font-size: 10vw;
  }

  .registerCont .infoCont .eventName span {
    font-size: 4vw;
    line-height: 4VW;
  }

  /* ----------------------------------------------------- */

  .registerCont .infoCont .logos .logoIdime {
    width: 20vw;
    height: calc(20vw / 3.5103);
  }

  .registerCont .infoCont .logos .logoAcreditacion {
    width: 16vw;
    height: calc(16vw / 1.0696);
  }

  .registerCont .infoCont .infoAcredit p {
    width: 90%;
    font-size: 3vw;
  }

  /* ------------------------------------------------------ */

  .registerCont .infoCont .social .media p {
    font-size: 3.2vw;
    margin: auto;
  }

  .registerCont .infoCont .social .media .socialMedia .icon {
    width: 3.2vw;
    height: 3.2vw;
  }

  .registerCont .infoCont .social .subTitle {
    font-size: 3.3vw;
  }
}
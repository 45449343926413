/* Myriad pro FONTS */

@font-face {
  font-family: Myriad-Pro-black-cond;
  src: url('./Myriad_Pro_Black_Condensed.woff');
}

@font-face {
  font-family: Myriad-Pro-cond;
  src: url('./Myriad-Pro-Condensed.woff');
}

@font-face {
  font-family: Myriad-Pro-Light-Cond;
  src: url('./Myriad-Pro-Light-Condensed.woff');
}

@font-face {
  font-family: Myriad-Pro-Semibold-Cond;
  src: url('./Myriad-Pro-Semibold-Condensed.woff');
}

@font-face {
  font-family: Myriad-Pro-SemiCond;
  src: url('./Myriad-Pro-SemiCondensed.woff');
}

@font-face {
  font-family: Myriad-Pro-Bold;
  src: url('./MYRIADPRO-BOLD.woff');
}

@font-face {
  font-family: Myriad-Pro-Bold-Cond;
  src: url('./MYRIADPRO-BOLDCOND.woff');
}

@font-face {
  font-family: Myriad-Pro-Cond-Italic;
  src: url('./MYRIADPRO-CONDIT.woff');
}

@font-face {
  font-family: Myriad-Pro-Light;
  src: url('./MyriadPro-Light.woff');
}

@font-face {
  font-family: Myriad-Pro-Regular;
  src: url('./MYRIADPRO-REGULAR.woff');
}

@font-face {
  font-family: Myriad-Pro-Semibold-italic;
  src: url('./MYRIADPRO-SEMIBOLDIT.woff');
}

.callContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: inherit;
  width: inherit;
}

.callNull {
  background-color: #121a24;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: inherit;
  width: inherit;
  color: white;
}

.callFrame {
  height: inherit;
  width: 80%;
  box-sizing: border-box;
  border: 0px;
}

.callFrame iframe {
  height: inherit;
}

.callFrame2 {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 0px;
}

.manageBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #121a24;
  width: 20%;
  height: 100%;
  color: white;
}

.inCall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.inCall p, .outCall p {
  width: 70%;
}

.inCall button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 15px;
  background: #121a24;
  box-shadow: inset 5px 5px 10px #0b1016, inset -5px -5px 10px #192432;
}

.inCall button:hover {
  box-shadow: inset 5px 5px 10px rgb(41, 15, 15), inset -5px -5px 10px #192432;
}

.outCall button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 15px;
  background: #121a24;
  box-shadow: inset 5px 5px 10px #0b1016, inset -5px -5px 10px #192432;
}

.outCall button:hover {
  box-shadow: inset 5px 5px 10px rgb(22, 41, 20), inset -5px -5px 10px #192432;
}

.outCall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

@media (orientation: portrait) {
  .callContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .callFrame {
    height: 60%;
    width: 100%;
    box-sizing: border-box;
    border: 0px;
  }
  .manageBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #121a24;
    width: 100%;
    height: 40%;
    color: white;
  }
}
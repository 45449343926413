.lobbyContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/lobby/lobbyBg.png');
}

.lobbyContainer .menuCont {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  display: flex;

  z-index: 1000;
}

.lobbyContainer .menuCont .logout {
  border: none;
  outline: none;
  border-radius: 200px;

  padding: 5px 10px;
  margin: 2% 5% auto auto;

  background-color: #FFF;
  color: #00AAA7;
  font-family: Myriad-Pro-Bold;
  font-size: 1vw;

  cursor: pointer;
}

/* -------------------------------------------- */

.lobbyContainer .sections {
  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

/* width and height */
.lobbyContainer .sections::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.lobbyContainer .sections::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.lobbyContainer .sections::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}

/* Handle on hover */
.lobbyContainer .sections::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

/* --------------------------- */
/* --------------------------- */
/* --------------------------- */

.lobbyContainer .sections .principal {
  width: 35%;
  height: 90%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

/* ------------------------------------ */

.lobbyContainer .sections .principal .eventName {
  width: 80%;

  background-color: #FFFD;
  border-radius: 10px;

  margin: auto auto 8% auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.lobbyContainer .sections .principal .eventName p {
  margin: 5px;
  font-size: 4.5vw;
  color: #878787;
  font-weight: 800;
  font-family: Myriad-Pro-black-cond;
}

.lobbyContainer .sections .principal .eventName span {
  font-size: 1.7vw;
  line-height: 1.7VW;
  color: #00AFAA;
  font-weight: 600;
  font-family: Myriad-Pro-black-cond;

  width: 80%;

  margin: 5px;
}

/* --------------------- */


.lobbyContainer .sections .principal .counterCont {
  width: 80%;

  margin: 10px auto;
  padding: 13% 0%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #FFFA;
  border-radius: 20px;
}

.lobbyContainer .sections .principal .counterCont .contValue {
  margin: 10px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.lobbyContainer .sections .principal .counterCont .contValue h2 {
  color: #878787;
  font-size: 1.4vw;
  font-family: Myriad-Pro-Bold-Cond;

  margin: auto;
}

.lobbyContainer .sections .principal .counterCont .contValue .valueCont {
  width: 5vw;
  height: 5vw;

  margin: 10px auto;

  background-color: #FFF;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.lobbyContainer .sections .principal .counterCont .contValue .valueCont h3 {
  font-size: 3.8vw;
  line-height: 3.8VW;
  color: #00AFAA;
  font-weight: 300;
  font-family: Myriad-Pro-Light-Cond;

  margin: auto;
}

/* ------------------------------------------ */


.lobbyContainer .sections .width .counterCont {
  width: 100%;

  margin: 10px auto;
  padding: 15% 0%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #FFFA;
  border-radius: 20px;
}

.lobbyContainer .sections .width .counterCont .contValue {
  margin: 10px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.lobbyContainer .sections .width .counterCont .contValue h2 {
  color: #878787;
  font-size: 1.8vw;
  font-family: Myriad-Pro-Bold-Cond;

  margin: auto;
}

.lobbyContainer .sections .width .counterCont .contValue .valueCont {
  width: 7vw;
  height: 7vw;

  margin: 10px auto;

  background-color: #FFF;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.lobbyContainer .sections .width .counterCont .contValue .valueCont h3 {
  font-size: 3.6vw;
  line-height: 3.6VW;
  color: #00AFAA;
  font-weight: 300;
  font-family: Myriad-Pro-Light-Cond;

  margin: auto;
}

/* ------------------------------------------ */

.lobbyContainer .sections .principal .mainRoom {
  border: none;
  outline: none;
  border-radius: 200px;

  padding: 5px 8%;
  margin:  5px auto auto auto;

  background-color: #00AAA7;
  color: #FFF;
  font-family: Myriad-Pro-Bold;
  font-size: 1.3vw;

  z-index: 10;

  cursor: pointer;
}

/* ------------------------------------------ */

.lobbyContainer .sections .principal .logos {
  width: 100%;

  margin: auto auto 0 auto;

  display: flex;
}

.lobbyContainer .sections .principal .logos div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.lobbyContainer .sections .principal .logos .logoIdime {
  width: 8vw;
  height: calc(8vw / 3.5103);
  background-image: url('../../img/cover/logoIdime_1.png');

  margin: auto 5% auto auto;
}

.lobbyContainer .sections .principal .logos .logoAcreditacion {
  width: 7vw;
  height: calc(7vw / 1.0696);
  background-image: url('../../img/cover/logoOnac.png');

  margin: auto auto auto 5%;
}

/* ======================================= */

.lobbyContainer .sections .dataCont {
  position: relative;

  margin: 0;

  display: flex;
  flex-flow: column;
}

.lobbyContainer .sections .dataCont .arrow {
  position: absolute;
  left: -25px;
  top: 50%;

  width: 15px;
  height: 7%;

  padding: 5px;
  color: #FFF;

  background-color: #00AAA7;
  border-radius: 10px 0px 0px 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  transform: translateY(-50%);

  cursor: pointer;
}
.lobbyContainer .sections .dataCont .arrow::before {
  content: "▶";
}

.lobbyContainer .sections .dataCont .left {
  left: -35px;

  height: calc(1.6vw * 7);
  width: 30px;
}

.lobbyContainer .sections .dataCont .left::before {
  content: "Conferencistas";
  transform: rotate(-90deg);
  font-size: 1.6vw;
}

.lobbyContainer .sections .dataCont .container {
  width: 65vw;
  height: 100vh;

  background-color: #00AAA7CC;
}

.lobbyContainer .sections .dataCont .agenda {
  width: 100%;
  height: 55%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;
}

/* =================================================== */

.lobbyContainer .sections .dataCont .speakers {
  width: 100%;
  height: calc(45% - 1px);

  margin: auto;

  display: flex;
  flex-flow: column;

  border: none;
  border-top: 1px solid #FFF;
}

.lobbyContainer .sections .dataCont .speakers .conferencistas {
  color: #FFF;
  font-size: 2vw;
  font-family: Myriad-Pro-Bold;

  margin: 30px auto 20px auto;
}

/* ======================================= */
/* ======================================= */
/* ======================================= */
/* ======================================= */
/* ======================================= */
/* ======================================= */


.lobbyContainer .sections .dataContRes {
  position: relative;

  margin: 0;

  display: none;
  flex-flow: column;
}

.lobbyContainer .sections .dataContRes .arrow {
  position: absolute;
  left: -25px;
  top: 50%;

  width: 15px;
  height: 7%;

  padding: 5px;
  color: #FFF;

  background-color: #00AAA7;
  border-radius: 10px 0px 0px 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  transform: translateY(-50%);

  cursor: pointer;
}

.lobbyContainer .sections .dataContRes .left {
  transform: rotate(180deg) translateY(50%);
  border-radius: 0px 10px 10px 0px;
}

.lobbyContainer .sections .dataContRes .container {
  width: 65vw;
  height: 100vh;

  background-color: #00AAA7CC;
}

.lobbyContainer .sections .dataContRes .agenda {
  width: 100%;
  height: 55%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;
}

/* =================================================== */

.lobbyContainer .sections .dataContRes .speakers {
  width: 100%;
  height: calc(45% - 1px);

  margin: auto;

  display: flex;
  flex-flow: column;

  border: none;
  border-top: 1px solid #FFF;
}

.lobbyContainer .sections .dataContRes .speakers .conferencistas {
  color: #FFF;
  font-size: 2vw;
  font-family: Myriad-Pro-Bold;

  margin: 30px auto 20px auto;
}


/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .lobbyContainer .menuCont .logout {
    font-size: 2vw;
  }

  .lobbyContainer .sections {
    display: flex;
    flex-flow: column;
  }

  /* ========================================== */

  .lobbyContainer .sections .principal {
    width: 75%;
    height: 90%;

    margin: 10% auto 0% auto;
  }

  /* ------------------------------------ */

  .lobbyContainer .sections .principal .eventName {
    width: 80%;
    margin: auto auto 5% auto;
  }

  .lobbyContainer .sections .principal .eventName p {
    font-size: 10vw;
  }

  .lobbyContainer .sections .principal .eventName span {
    font-size: 4.4vw;
    line-height: 4.4VW;
  }

  /* --------------------- */

  .lobbyContainer .sections .principal .counterCont {
    padding: 8% 0%;
  }

  .lobbyContainer .sections .principal .counterCont .contValue h2 {
    font-size: 2.5vw;
  }

  .lobbyContainer .sections .principal .counterCont .contValue .valueCont {
    width: 12vw;
    height: 12vw;
  }

  .lobbyContainer .sections .principal .counterCont .contValue .valueCont h3 {
    font-size: 8.6vw;
    line-height: 8.6VW;
  }

  /* ------------------------------------------ */

  .lobbyContainer .sections .principal .mainRoom {
    padding: 5px 10%;
    font-size: 2.5vw;
  }

  /* ========================================== */

  .lobbyContainer .sections .principal .logos {
    display: none;
  }

  .lobbyContainer .sections .dataCont {
    display: none;
  }

  /* ============================ */

  .lobbyContainer .sections .dataContRes {
    position: relative;

    margin: 40px auto 0 auto;

    display: flex;
    flex-flow: column;
  }

  .lobbyContainer .sections .dataContRes .arrow {
    display: none;
  }

  .lobbyContainer .sections .dataContRes .left {
    display: none;
  }

  .lobbyContainer .sections .dataContRes .container {
    width: 100vw;
    height: auto;
  }

  .lobbyContainer .sections .dataContRes .agenda {
    width: 100%;
    height: auto;
  }

  /* =================================================== */

  .lobbyContainer .sections .dataContRes .speakers {
    width: 100%;
    height: auto;
  }

  .lobbyContainer .sections .dataContRes .speakers .conferencistas {
    color: #FFF;
    font-size: 6vw;
    font-family: Myriad-Pro-Bold;
    text-align: center;

    width: 100%;

    margin: 30px auto;
  }

  /* ------------------------------------------ */

  .lobbyContainer .sections .logosResponsive {
    width: 100%;

    margin: 10% auto 5% auto;

    display: flex;

    z-index: 2000;
  }

  .lobbyContainer .sections .logosResponsive div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .lobbyContainer .sections .logosResponsive .logoIdime {
    width: 20vw;
    height: calc(20vw / 3.5103);
    background-image: url('../../img/cover/logoIdime_1.png');

    margin: auto 5% auto auto;
  }

  .lobbyContainer .sections .logosResponsive .logoAcreditacion {
    width: 18vw;
    height: calc(18vw / 1.0696);
    background-image: url('../../img/cover/logoOnac.png');

    margin: auto auto auto 5%;
  }
}

/* ======================================= */
/* ======================================= */
/* ======================================= */

@media (max-width: 640px) {
  .lobbyContainer .menuCont .logout {
    font-size: 3.5vw;
  }

  /* ========================================== */

  .lobbyContainer .sections .principal {
    width: 85%;
    height: 90%;

    margin: 15% auto 0% auto;
  }

  /* ------------------------------------ */

  .lobbyContainer .sections .principal .eventName {
    width: 90%;
    margin: auto auto 5% auto;
  }

  .lobbyContainer .sections .principal .eventName p {
    font-size: 12.5vw;
  }

  .lobbyContainer .sections .principal .eventName span {
    font-size: 4.9vw;
    line-height: 4.9VW;
  }

  /* --------------------- */

  .lobbyContainer .sections .principal .counterCont {
    padding: 8% 0%;
  }

  .lobbyContainer .sections .principal .counterCont .contValue h2 {
    font-size: 4vw;
  }

  .lobbyContainer .sections .principal .counterCont .contValue .valueCont {
    width: 12vw;
    height: 12vw;
  }

  .lobbyContainer .sections .principal .counterCont .contValue .valueCont h3 {
    font-size: 8.6vw;
    line-height: 8.6VW;
  }

  /* ------------------------------------------ */

  .lobbyContainer .sections .principal .mainRoom {
    padding: 5px 10%;
    font-size: 3.5vw;
    letter-spacing: 1px;
  }

  /* ========================================== */

  .lobbyContainer .sections .principal .logos {
    display: none;
  }

  .lobbyContainer .sections .dataCont {
    display: none;
  }

  /* ============================ */

  .lobbyContainer .sections .dataContRes {
    position: relative;

    margin: 40px auto 0 auto;

    display: flex;
    flex-flow: column;
  }

  .lobbyContainer .sections .dataContRes .arrow {
    display: none;
  }

  .lobbyContainer .sections .dataContRes .left {
    display: none;
  }

  .lobbyContainer .sections .dataContRes .container {
    width: 100vw;
    height: auto;
  }

  .lobbyContainer .sections .dataContRes .agenda {
    width: 100%;
    height: auto;
  }

  /* =================================================== */

  .lobbyContainer .sections .dataContRes .speakers {
    width: 100%;
    height: auto;
  }

  .lobbyContainer .sections .dataContRes .speakers .conferencistas {
    color: #FFF;
    font-size: 6vw;
    font-family: Myriad-Pro-Bold;
    text-align: center;

    width: 100%;

    margin: 30px auto;
  }

  /* ------------------------------------------ */

  .lobbyContainer .sections .logosResponsive {
    width: 100%;

    margin: 10% auto 5% auto;

    display: flex;

    z-index: 2000;
  }

  .lobbyContainer .sections .logosResponsive div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .lobbyContainer .sections .logosResponsive .logoIdime {
    width: 20vw;
    height: calc(20vw / 3.5103);
    background-image: url('../../img/cover/logoIdime_1.png');

    margin: auto 5% auto auto;
  }

  .lobbyContainer .sections .logosResponsive .logoAcreditacion {
    width: 18vw;
    height: calc(18vw / 1.0696);
    background-image: url('../../img/cover/logoOnac.png');

    margin: auto auto auto 5%;
  }
}

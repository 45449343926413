div[class^="triviaUser"] {
  width: 100%;

  margin: 10px auto;

  display: flex;

  color: #FFF;
  font-family: Myriad-Pro-Regular;

  z-index: 1000;
}

.triviaUser1 .triviaCont {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;

  background-color: #FFF;
}

.triviaUser1 h1 {
  line-height: 2vw;
  font-size: 2vw;
  margin: 50px auto;
  text-align: center;
  color: #00AFAA;
}

/* ===================================== */

.triviaUser2 {
  flex-flow: column;
}

.triviaUser2 .triviaCont {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;

  background-color: transparent;
}

.triviaUser2 .triviaTimeAndQuestion {
  width: 90%;
  margin: 10px auto;
  display: flex;
  flex-flow: column;

  border-bottom: 2px solid #00AFAA;
}

.triviaUser2 .triviaTimeAndQuestion h3 {
  font-size: 3.5vw;
  line-height: 4vw;
  font-weight: 100;
  margin: 0;

  margin: auto;

  color: #00AFAA;
}

.triviaUser2 .triviaTimeAndQuestion h2 {
  font-size: 2vw;
  line-height: 2vw;
  margin: 0;

  margin: 10px auto;

  color: #878787;
  text-align: center;
}

/* ---------------------------------------- */

.triviaUser2 .triviaAnswers {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;
}

.triviaUser2 .triviaAnswers .answersCont {
  width: 100%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.triviaUser2 .triviaAnswers .answersCont .triviaAnswer {
  margin: 5px;
  padding: 2% 5%;

  border: 0;
  border-radius: 5px;

  cursor: pointer;

  min-width: 10%;
  background-color: #878787;
  font-weight: 900;
  color: #FFF;
  font-size: 1.5vw;

  transition: .15s;
}

.triviaUser2 .triviaAnswers .triviaAnswer:hover {
  transform: scale(.97);
  background-color: #FCC43D;
}

/* ================================================== */

.triviaUser5 {
  display: flex;
  flex-direction: column;
}

.triviaUser5 .triviaCont {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;

  background-color: transparent;
}

.triviaUser5 .triviaCont h2 {
  line-height: 3vw;
  font-size: 3vw;
  font-weight: 200;
  text-align: center;

  margin: 10px auto;

  color: #00AFAA;
}

.triviaUser5 .triviaCont .winner {
  width: 90%;

  margin: 10px auto;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Myriad-Pro-Light;
  font-weight: 300;

  border-top: 1px solid #878787;
}

.triviaUser5 .triviaCont .winner h3 {
  margin: 10px ;
  color: #00AFAA;
  text-align: center;
  font-size: 1.5vw;
}

.triviaUser5 .triviaCont .winner h4 {
  margin: 10px ;
  color: #878787;
  text-align: center;
  font-size: 2vw;
}

/* ==================================================== */

.triviaUser6 {
  display: flex;
  flex-direction: column;
}

.triviaUser6 .triviaCont {
  width: 95%;

  margin: 10px auto;
  padding: 2.5%;

  display: flex;
  flex-flow: column;

  background-color: transparent;
}

.triviaUser6 .triviaCont .canvasCont {
  position: relative;

  width: 16vw;
  height: calc(16vw / 1.7777);

  margin: auto;
}

.triviaUser6 .triviaCont h1 {
  line-height: 2.5vw;
  font-size: 2.5vw;
  margin: 2vw auto;
  text-align: center;
  color: #00AFAA;
}

.triviaUser6 .triviaCont .optionsCont {
  width: 100%;

  display: flex;
  flex-flow: column;
}

.triviaUser6 .triviaCont .optionsCont .option {
  width: 90%;

  margin: 5px auto;
  padding: 5px 0px;

  border-bottom: 1px solid #00AFAA;

  display: flex;
  align-items: center;
  justify-content: center;
}

.triviaUser6 .triviaCont .optionsCont .option h2 {
  margin: 0px 5px;
  font-size: 1.4vw;
  color: #878787;
  font-family: Myriad-Pro-Bold;
}

.triviaUser6 .triviaCont .optionsCont .option p {
  margin: 0px 10px;
  color: #00AFAA;
  font-size: 1.3vw;
  font-family: Myriad-Pro-Light;
}

/* ==================================================== */

@media (max-width: 1024px) {

  .triviaUser5 .triviaCont .winner {
    width: 90%;
  }

  .triviaUser5 .triviaCont .winner h3 {
    margin: 10px ;
    color: #00AFAA;

    font-size: 2vw;
  }

  .triviaUser5 .triviaCont .winner h4 {
    margin: 10px ;
    color: #878787;

    font-size: 2.5vw;
  }

  /* ================================= */

  .triviaUser6 .triviaCont .canvasCont {
    width: 25vw;
    height: calc(25vw / 1.7777);
  }
}

@media (max-width: 768px) {
  .triviaUser1 h1 {
    line-height: 3vw;
    font-size: 3vw;;
  }

  /* =========================== */

  .triviaUser6 .triviaCont h1 {
    line-height: 3.5vw;
    font-size: 3.5vw;
  }

  .triviaUser6 .triviaCont .canvasCont {
    width: 30vw;
    height: calc(30vw / 1.7777);
  }

  .triviaUser6 .triviaCont .optionsCont .option h2 {
    font-size: 2.5vw;
  }

  .triviaUser6 .triviaCont .optionsCont .option p {
    font-size: 2.5vw;
  }
}

@media (max-width: 640px) {
  .triviaUser2 .triviaTimeAndQuestion h3 {
    font-size: 5vw;
    line-height: 5vw;
  }

  .triviaUser2 .triviaTimeAndQuestion h2 {
    font-size: 4vw;
    line-height: 4vw;
  }

  .triviaUser2 .triviaAnswers .answersCont .triviaAnswer {
    font-size: 2vw;
  }

  /* ========================================== */

  .triviaUser5 .triviaCont h2 {
    line-height: 5vw;
    font-size: 5vw;
  }

  .triviaUser5 .triviaCont .winner h3 {
    margin: 10px ;
    color: #00AFAA;

    font-size: 4vw;
  }

  .triviaUser5 .triviaCont .winner h4 {
    margin: 10px ;
    color: #878787;

    font-size: 4vw;
  }

  /* ========================================= */

  .triviaUser6 .triviaCont h1 {
    line-height: 4.5vw;
    font-size: 4.5vw;
  }

  .triviaUser6 .triviaCont .canvasCont {
    width: 45vw;
    height: calc(45vw / 1.7777);
  }

  .triviaUser6 .triviaCont .canvasCont #myChart {
    width: 100%;
  }

  .triviaUser6 .triviaCont .optionsCont .option h2 {
    font-size: 2.4vw;
  }

  .triviaUser6 .triviaCont .optionsCont .option p {
    font-size: 2.3vw;
  }
}

@media (max-width: 480px) {
  .triviaUser1 h1 {
    line-height: 4.5vw;
    font-size: 4.5vw;;
  }

  /* ========================================= */

  .triviaUser2 .triviaTimeAndQuestion h3 {
    font-size: 8vw;
    line-height: 8vw;
  }

  .triviaUser2 .triviaTimeAndQuestion h2 {
    font-size: 6vw;
    line-height: 6vw;
  }

  .triviaUser2 .triviaAnswers .answersCont .triviaAnswer {
    font-size: 4vw;
  }

  /* ========================================= */

  .triviaUser5 .triviaCont h2 {
    line-height: 7vw;
    font-size: 7vw;
  }

  .triviaUser5 .triviaCont .winner h3 {
    margin: 10px ;
    color: #00AFAA;

    font-size: 5vw;
  }

  .triviaUser5 .triviaCont .winner h4 {
    margin: 10px ;
    color: #878787;
    font-size: 5vw;
  }

  /* ========================================= */

  .triviaUser6 .triviaCont h1 {
    line-height: 6vw;
    font-size: 6vw;
  }

  .triviaUser6 .triviaCont .canvasCont {
    width: 50vw;
    height: calc(50vw / 1.7777);
  }

  .triviaUser6 .triviaCont .optionsCont .option h2 {
    font-size: 3.5vw;
  }

  .triviaUser6 .triviaCont .optionsCont .option p {
    font-size: 3.5vw;
  }
}

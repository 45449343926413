.coverCont {
  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../img/cover/bgCover.png');
}

.coverCont .infoCont {
  width: 45%;
  height: 100%;

  background-color: #FFFA;

  margin: auto 0 auto auto;
  display: flex;
  flex-flow: column;

  scrollbar-width: thin;
  scrollbar-color: #00AFAA transparent;

  overflow-x: hidden;
  overflow-y: auto;
}

.coverCont .infoCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}

.coverCont .infoCont::-webkit-scrollbar-track {
  background: transparent;
}

.coverCont .infoCont::-webkit-scrollbar-thumb {
  background: #00AFAA;
}

.coverCont .infoCont::-webkit-scrollbar-thumb:hover {
  background: #106461;
}

.coverCont .infoCont .title {
  width: 90%;

  margin: auto;
  padding: 5%;

  background-color: #FFFD;

  display: flex;
}

.coverCont .infoCont .title h2 {
  margin: auto;

  font-size: 3vw;
  line-height: 2.5vw;
  font-weight: 300;
  color: #00AFAA;
  text-align: start;
  font-family: Myriad-Pro-cond;
}

.coverCont .infoCont .title h2 .comp {
  color: #FFF;
  background-color: #00AFAA;
  padding: 0px 10px;
}

.coverCont .infoCont .title h2 .strong {
  font-weight: 600;
  font-family: Myriad-Pro-Bold-Cond;
}

/* ------------------------------------ */

.coverCont .infoCont .eventName {
  width: 50%;

  background-color: #FFFD;
  border-radius: 10px;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.coverCont .infoCont .eventName p {
  margin: 5px;
  font-size: 4vw;
  color: #878787;
  font-weight: 800;
  font-family: Myriad-Pro-black-cond;
}

.coverCont .infoCont .eventName span {
  font-size: 1.5vw;
  line-height: 1.5VW;
  color: #00AFAA;
  font-weight: 600;
  font-family: Myriad-Pro-black-cond;

  width: 60%;

  margin: 5px;
}

/* --------------------- */

.coverCont .infoCont .counterCont {
  width: 100%;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.coverCont .infoCont .counterCont .contValue {
  margin: 10px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.coverCont .infoCont .counterCont .contValue h2 {
  color: #878787;
  font-size: 1.4vw;
  font-family: Myriad-Pro-Bold-Cond;

  margin: auto;
}

.coverCont .infoCont .counterCont .contValue .valueCont {
  width: 5vw;
  height: 5vw;

  margin: 10px auto;

  background-color: #FFF;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.coverCont .infoCont .counterCont .contValue .valueCont h3 {
  font-size: 3vw;
  line-height: 3VW;
  color: #00AFAA;
  font-weight: 300;
  font-family: Myriad-Pro-Light-Cond;

  margin: auto;
}

/* ------------------------------------------ */

.coverCont .infoCont .btnCont {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.coverCont .infoCont .btnCont .login {
  color: #00AFAA;
  font-size: 1.3vw;
  line-height: 1.3vw;

  background-color: transparent;
  border: none;
  outline: none;

  margin: auto 5px auto auto;

  cursor: pointer;
}

.coverCont .infoCont .btnCont .register {
  background-color: #FCC43D;
  border: none;
  outline: none;
  border-radius: 100px;

  color: #FFF;
  font-size: 1.2vw;
  font-weight: 100;
  font-family: Myriad-Pro-Bold;

  margin: auto auto auto 10px;
  padding: 5px 5%;

  cursor: pointer;
}

/* ------------------------------------------ */

.coverCont .infoCont .logos {
  width: 100%;

  margin: auto;

  display: flex;
}

.coverCont .infoCont .logos div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.coverCont .infoCont .logos .logoIdime {
  width: 10vw;
  height: calc(10vw / 3.5103);
  background-image: url('../../img/cover/logoIdime_1.png');

  margin: auto 5% auto auto;
}

.coverCont .infoCont .logos .logoAcreditacion {
  width: 7vw;
  height: calc(7vw / 1.0696);
  background-image: url('../../img/cover/logoOnac.png');

  margin: auto auto auto 5%;
}

/* ---------------------------------------- */

.coverCont .infoCont .infoAcredit {
  width: 100%;

  margin: auto;

  background-color: #9B9A92;
}

.coverCont .infoCont .infoAcredit p {
  width: 70%;

  margin: 5px auto;

  color: #FFF;
  text-align: center;
  font-size: .9vw;
  font-family: Myriad-Pro-Light;
}

/* --------------------------------- */

.coverCont .infoCont .social {
  width: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  margin: auto;
}

.coverCont .infoCont .social .media {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;

  width: 100%;
}

.coverCont .infoCont .social .media a {
  text-decoration: none;
}

.coverCont .infoCont .social .media p {
  margin: auto 10px;

  color: #878787;
  font-size: 1vw;
  font-family: Myriad-Pro-Light;
}

.coverCont .infoCont .social .media p:hover,
.coverCont .infoCont .social .media .socialMedia:hover {
  color: #00AFAA;
}

.coverCont .infoCont .social .media .socialMedia {
  margin: auto 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.coverCont .infoCont .social .media .socialMedia .icon {
  width: 1vw;
  height: 1vw;

  margin: auto;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.coverCont .infoCont .social .media .socialMedia .face {
  background-image: url('../../img/cover/faceIcon.png');
}

.coverCont .infoCont .social .media .socialMedia .twitter {
  background-image: url('../../img/cover/twitterIcon.png');
}

.coverCont .infoCont .social .media .socialMedia .linkedin {
  background-image: url('../../img/cover/linkedinIcon.png');
}

.coverCont .infoCont .social .media .socialMedia p {
  margin: auto 2px;
}

.coverCont .infoCont .social .subTitle {
  color: #878787;
  font-size: 1.1vw;
  font-family: Myriad-Pro-Light;

  margin: 10px auto;
}

/* ====================================== */
/* ====================================== */
/* ====================================== */

@media screen and (max-width: 1100px) {
  .coverCont .infoCont {
    width: 55%;
  }

  .coverCont .infoCont .title h2 {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }

  /* ---------------------------------------------- */

  .coverCont .infoCont .eventName {
    width: 65%;
  }

  .coverCont .infoCont .eventName p {
    font-size: 4.5vw;
  }

  .coverCont .infoCont .eventName span {
    font-size: 2vw;
    line-height: 2VW;
  }

  /* ----------------------------------------------- */

  .coverCont .infoCont .counterCont .contValue h2 {
    font-size: 2vw;
  }

  .coverCont .infoCont .counterCont .contValue .valueCont {
    width: 8vw;
    height: 8vw;
  }

  .coverCont .infoCont .counterCont .contValue .valueCont h3 {
    font-size: 3.4vw;
    line-height: 3.4VW;
  }

  /* ----------------------------------------------------- */

  .coverCont .infoCont .btnCont .login {
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  .coverCont .infoCont .btnCont .register {
    font-size: 2.2vw;
  }

  /* ----------------------------------------------------- */

  .coverCont .infoCont .logos .logoIdime {
    width: 15vw;
    height: calc(15vw / 3.5103);
  }

  .coverCont .infoCont .logos .logoAcreditacion {
    width: 12vw;
    height: calc(12vw / 1.0696);
  }

  /* ------------------------------------------------------- */

  .coverCont .infoCont .infoAcredit p {
    width: 90%;
    font-size: 1.7vw;
  }

  /* ------------------------------------------------------ */

  .coverCont .infoCont .social .media p {
    font-size: 1.7vw;
  }

  .coverCont .infoCont .social .media .socialMedia .icon {
    width: 1.7vw;
    height: 1.7vw;
  }

  .coverCont .infoCont .social .subTitle {
    font-size: 1.8vw;
  }
}

/* ================================================================================================= */

@media screen and (max-width: 768px) {
  .coverCont {
    background-position: left;
  }

  .coverCont .infoCont {
    width: 90%;
    height: 85%;

    margin: auto;
  }

  .coverCont .infoCont .title h2 {
    font-size: 5vw;
    line-height: 5vw;
  }

  /* ---------------------------------------------- */

  .coverCont .infoCont .eventName {
    width: 70%;
  }

  .coverCont .infoCont .eventName p {
    font-size: 7vw;
  }

  .coverCont .infoCont .eventName span {
    font-size: 3.4vw;
    line-height: 3.4VW;
  }

  /* ----------------------------------------------- */

  .coverCont .infoCont .counterCont .contValue h2 {
    font-size: 4vw;
  }

  .coverCont .infoCont .counterCont .contValue .valueCont {
    width: 15vw;
    height: 15vw;
  }

  .coverCont .infoCont .counterCont .contValue .valueCont h3 {
    font-size: 5vw;
    line-height: 5VW;
  }

  /* ----------------------------------------------------- */

  .coverCont .infoCont .btnCont .login {
    font-size: 3.3vw;
    line-height: 3.3vw;
  }

  .coverCont .infoCont .btnCont .register {
    font-size: 3.2vw;
  }

  /* ----------------------------------------------------- */

  .coverCont .infoCont .logos .logoIdime {
    width: 25vw;
    height: calc(25vw / 3.5103);
  }

  .coverCont .infoCont .logos .logoAcreditacion {
    width: 20vw;
    height: calc(20vw / 1.0696);
  }

  /* ------------------------------------------------------- */

  .coverCont .infoCont .infoAcredit p {
    width: 90%;
    font-size: 3vw;
  }

  /* ------------------------------------------------------ */

  .coverCont .infoCont .social .media p {
    font-size: 3vw;
  }

  .coverCont .infoCont .social .media .socialMedia .icon {
    width: 3vw;
    height: 3vw;
  }

  .coverCont .infoCont .social .subTitle {
    font-size: 3.1vw;
  }
}
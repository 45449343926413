.agendaContainer {
  width: 100%;
  height: 95%;

  margin: auto auto 0 auto;
  padding-bottom: 5px;

  display: flex;
  flex-flow: column;
}

.agendaContainer .titleCont {
  width: 100%;
}

.agendaContainer .titleCont h2 {
  width: 100%;

  font-size: 2.5vw;
  line-height: 2.5vw;
  letter-spacing: 1px;
  font-family: Myriad-Pro-Bold;
  color: #FFF;

  margin: auto;
  padding: 3% 10%;
}

/* ----------------------------------- */

.agendaContainer .content {
  width: 95%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

.agendaContainer .content::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.agendaContainer .content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.agendaContainer .content::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}
.agendaContainer .content::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

/* ----------------------- */

.agendaContainer .content .Cont {
  width: 32%;

  margin: 5px auto;

  display: flex;
}

.agendaContainer .content .Cont .square {
  width: 1vw;
  min-width: 1vw;
  height: 1vw;

  margin: 3%;

  background-color: #FCC43D;
}

.agendaContainer .content .Cont .infoCont {
  display: flex;
  flex-flow: column;
}

.agendaContainer .content .Cont .infoCont .subtitle {
  font-family: Myriad-Pro-Semibold-italic;
  color: #FFF;
  font-size: 1.1vw;
  line-height: 1.3vw;

  margin: 0px auto 10px auto;
}

.agendaContainer .content .Cont .infoCont .title {
  font-family: Myriad-Pro-Light;
  color: #FFF;
  font-size: 1.1vw;
  line-height: 1.4vw;
  font-weight: 100;

  margin: 0px auto 10px auto;
}

.agendaContainer .content .Cont .infoCont .speakerContA {
  width: 100%;

  display: flex;
  flex-flow: column;

  margin: 2% auto;
}

.agendaContainer .content .Cont .infoCont .speakerContA h2 {
  font-family: Myriad-Pro-Bold-Cond;
  color: #FFF;
  font-size: 1vw;
  line-height: 1.2vw;

  margin: 10px auto;
  width: 100%;
}

.agendaContainer .content .Cont .infoCont .speakerContA h3 {
  font-family: Myriad-Pro-Light;
  color: #FFF;
  font-size: 1vw;
  line-height: 1.2vw;
  font-weight: 100;

  margin: 0px auto;
  width: 100%;
}

.agendaContainer .content .Cont .infoCont .time {
  font-family: Myriad-Pro-Bold;
  color: #FCC43D;
  font-size: 1vw;
  line-height: 1vw;
  font-weight: 100;

  margin: 0px auto;
  width: 100%;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .agendaContainer {
    width: 90%;
    height: auto;
  }

  .agendaContainer .titleCont h2 {
    font-size: 5.5vw;
    line-height: 5.5vw;
    text-align: center;
    padding: 3% 0%;
  }

  /* ----------------------------------- */

  .agendaContainer .content {
    display: flex;
    flex-flow: column;
  }

  /* ----------------------- */

  .agendaContainer .content .Cont {
    width: 90%;

    margin: 10px auto 50px auto;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .agendaContainer .content .Cont .square {
    width: 2vw;
    min-width: 2vw;
    height: 2vw;
  }

  .agendaContainer .content .Cont .infoCont {
    display: flex;
    flex-flow: column;
  }

  .agendaContainer .content .Cont .infoCont .subtitle {
    font-size: 4.1vw;
    line-height: 4.3vw;
    text-align: center;
  }

  .agendaContainer .content .Cont .infoCont .title {
    font-size: 3.4vw;
    line-height: 3.6vw;
    text-align: center;
  }

  .agendaContainer .content .Cont .infoCont .speakerContA h2 {
    font-size: 3vw;
    line-height: 3.2vw;
    text-align: center;
  }

  .agendaContainer .content .Cont .infoCont .speakerContA h3 {
    font-size: 2.5vw;
    line-height: 2.5vw;
    text-align: center;
  }

  .agendaContainer .content .Cont .infoCont .time {
    font-size: 3vw;
    line-height: 3vw;
    text-align: center;
  }

}

@media (max-width: 640px) {

  .agendaContainer .titleCont h2 {
    font-size: 6.5vw;
    line-height: 6.5vw;
  }

  /* ----------------------- */
  .agendaContainer .content .Cont .square {
    width: 3vw;
    min-width: 3vw;
    height: 3vw;
  }

  .agendaContainer .content .Cont .infoCont .subtitle {
    font-size: 5.1vw;
    line-height: 5.3vw;
  }

  .agendaContainer .content .Cont .infoCont .title {
    font-size: 4.4vw;
    line-height: 4.6vw;
  }

  .agendaContainer .content .Cont .infoCont .speakerContA h2 {
    font-size: 5vw;
    line-height: 5.2vw;
  }

  .agendaContainer .content .Cont .infoCont .speakerContA h3 {
    font-size: 4.5vw;
    line-height: 4.5vw;
  }

  .agendaContainer .content .Cont .infoCont .time {
    font-size: 4vw;
    line-height: 4vw;
    text-align: center;
  }

}
.speakerCont {
  position: relative;

  width: 95%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

.speakerCont::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.speakerCont::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.speakerCont::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}
.speakerCont::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

/* ------------------------------------------ */

.speakerCont .speaker {
  width: 12vw;

  margin: 10px;

  border-radius: 10px;

  display: flex;
  flex-flow: column;

  background-color: #FFF9;
}

.speakerCont .speaker .close {
  position: absolute;
  top: 1px;
  right: 1px;

  background-color: #00AAA7;
  border-radius: 200px;
  color: #FFF;
  font-family: Myriad-Pro-Bold;

  width: 25px;
  min-width: 25px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.speakerCont .speaker .modalCont {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: #0004;

  margin: auto;

  display: flex;

  z-index: 2000;
}

.speakerCont .speaker .infoSp {
  position: relative;

  margin: auto 20%;
  padding: 5px 10px;

  background-color: #FCC43D;
  border-radius: 5px;

  z-index: 1000;
}

.speakerCont .speaker .infoSp h2 {
  color: #FFF;
  font-size: 1.5vw;
  text-align: center;
  font-family: Myriad-Pro-Bold;

  margin: 20px auto;
}
.speakerCont .speaker .infoSp p {
  color: #FFF;
  font-size: 1.2vw;
  line-height: 1.5vw;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 100;
  font-family: Myriad-Pro-Light;
}

.speakerCont .speaker .image {
  position: relative;

  width: 10vw;
  height: 10vw;
  min-height: 10vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FFF;

  border-radius: 8px;
  border: 3px solid #FFF;

  margin: 10px auto;

  cursor: pointer;
}

.speakerCont .speaker .image .plus {
  position: absolute;
  top: -5px;
  right: -5px;

  width: 20px;
  min-width: 20px;
  height: 20px;

  background-color: #00AAA7;
  border-radius: 100px;

  display: flex;
}

.speakerCont .speaker .image .plus p {
  margin: auto;

  color: #FFF;
  font-size: 1.5vw;
  line-height: 0vw;
  font-family: Myriad-Pro-Bold;
}

.speakerCont .speaker .info {
  width: 80%;

  margin: auto auto 10px auto;

  display: flex;
  flex-flow: column;

  text-align: center;

  border: none;
  border-top: 1px solid #00AAA7;
}

.speakerCont .speaker .info .name {
  width: 100%;
  margin: 5px auto;

  font-size: 1.3vw;
  line-height: 1.3vw;
  color: #00AAA7;
  font-family: Myriad-Pro-Bold;
}

.speakerCont .speaker .info .desc {
  width: 100%;
  margin: 2px auto;

  font-size: .8vw;
  line-height: .8vw;
  color: #555;
  font-family: Myriad-Pro-Light;
  font-weight: 100;
}

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .speakerCont .speaker {
    width: 25vw;
    min-width: 230px;
  }

  .speakerCont .speaker .image .plus p {
    font-size: 2.5vw;
  }

  .speakerCont .speaker .infoSp h2 {
    font-size: 4.5vw;
  }
  .speakerCont .speaker .infoSp p {
    font-size: 3vw;
    line-height: 3vw;
  }

  .speakerCont .speaker .image {
    width: 22vw;
    height: 23vw;
    min-height: 200px;
    min-width: 200px;
  }

  .speakerCont .speaker .info .name {
    font-size: 2.3vw;
    line-height: 2.3vw;
  }

  .speakerCont .speaker .info .desc {
    font-size: 1.8vw;
    line-height: 1.8vw;
  }
}

@media (max-width: 640px) {

  .speakerCont .speaker .image .plus p {
    font-size: 4vw;
  }

  .speakerCont .speaker .infoSp h2 {
    font-size: 7vw;
  }
  .speakerCont .speaker .infoSp p {
    font-size: 5vw;
    line-height: 5.2vw;
  }

  .speakerCont .speaker .image {
    width: 22vw;
    height: 23vw;
    min-height: 200px;
    min-width: 200px;
  }

  .speakerCont .speaker .info .name {
    font-size: 6vw;
    line-height: 6vw;
  }

  .speakerCont .speaker .info .desc {
    font-size: 4.5vw;
    line-height: 4.5vw;
  }
}
.checkBoxContainer {
  position: relative;

  width: 90%;
  margin: 10px auto;
  padding: 10px;

  display: flex;
  flex-flow: row;
}

.checkBoxContainer .textCont {
  font-size: 1vw;
  font-family: Myriad-Pro-SemiCond;
  font-weight: 300;
  text-decoration: none;
  color: #878787;
  text-align: start;
}

.checkBoxContainer .textCont span {
  cursor: pointer;
}

.checkBoxContainer .customCheckBox {
  position: relative;

  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;
}

.checkBoxContainer .customCheckBox .checkCont {
  position: relative;
  z-index: 10;
  margin: auto 20px;

  width: 33px;
  min-width: 33px;
  height: 33px;

  display: flex;

  cursor: pointer;
}

.checkBoxContainer .customCheckBox .check {
  width: 25px;
  min-width: 25px;
  height: 25px;

  background-color: #FFF0;
  border: 1px solid #00AFAA;
  border-radius: 3px;

  color: #DDD;

  margin: 0px auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.checkBoxContainer .customCheckBox .ok {
  color: #FFF;
  background-color: #00AFAA;
  border: 1px solid #00AFAA;
}

/* ---------------------------- */

.checkBoxContainer .ModalCont {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  flex-flow: column;

  z-index: 1000;
}

.checkBoxContainer .ModalCont .modalstyle {
  position: relative;

  width: 55%;
  height: 75%;

  margin: auto;

  display: flex;
}

.checkBoxContainer .ModalCont .modalInner {
  width: 90%;
  height: 90%;

  background-color: #FFF;
  border-radius: 20px;

  margin: auto;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00AFAA transparent;

  z-index: 1010;
}

/* width and height */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar {
  width: 10px; height: 10px;
}

/* Track */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-thumb {
  background: #00AFAA;
  border-radius: 10px;
}

/* Handle on hover */
.checkBoxContainer .ModalCont .modalInner::-webkit-scrollbar-thumb:hover {
  background: #00717e;
}

/* ----------------------------------------------- */

.checkBoxContainer .ModalCont .close {
  position: absolute;
  top: 14%;
  right: 23%;

  width: 25px;
  height: 25px;
  min-width: 25px;

  background-color: #00AFAA;
  border-radius: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #FFF;
  font-family: Myriad-Pro-Bold;

  cursor: pointer;

  z-index: 100;
}

.checkBoxContainer .ModalCont .modalInner iframe {
  width: 95%;
  height: 95%;

  margin: auto;
}

/* ======================================== */
/* ======================================== */
/* ======================================== */


@media screen and (max-width: 1024px) {
  .checkBoxContainer .textCont {
    font-size: 1.8vw;
  }

  .checkBoxContainer {
    margin: 5px auto;
  }

  .checkBoxContainer .customCheckBox {
    font-size: 14px;
  }

  .checkBoxContainer .ModalCont .close {
    position: absolute;
    top: 12%;
    right: 8%;
  }

  .checkBoxContainer .ModalCont .modalstyle {
    width: 85%;
    height: 75%;
  }

  .checkBoxContainer .ModalCont .modalInner h2 {
    font-size: 5.5vw;
  }

  .checkBoxContainer .ModalCont .modalInner .textModal {
    font-size: 2.3vw;
  }
}

@media screen and (max-width: 768px) {
  .checkBoxContainer .textCont {
    font-size: 2.6vw;
  }
}

@media screen and (max-width: 640px) {
  .checkBoxContainer .customCheckBox .check {
    width: 30px;
    min-width: 30px;
    height: 30px;

    margin: auto;
  }

  .checkBoxContainer .textCont {
    font-size: 3vw;
  }

  .checkBoxContainer .ModalCont .modalstyle {
    width: 95%;
    height: 75%;
  }

  .checkBoxContainer .ModalCont .modalInner h2 {
    font-size: 6.5vw;
  }

  .checkBoxContainer .ModalCont .modalInner .textModal {
    font-size: 3vw;
  }
}

@media (max-width: 480px) {
  .checkBoxContainer .customCheckBox .check {
    width: 29px;
    min-width: 29px;
    height: 29px;
  }

  .checkBoxContainer .textCont {
    font-size: 3.7vw;
  }
}

input:-webkit-autofill {
  border: 1px solid #006671;
  -webkit-text-fill-color: #006671;
  -webkit-box-shadow: 0 0 0px 10000px #FAFAFA inset;
  box-shadow: 0 0 0px 10000px #15c6da inset;
}

.inputContainer {
  position: relative;
  width: 100%;

  margin: auto;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.inputContainer p.warning {
  width: 100%;
  text-align: center;
  font-size: 1em;
  line-height: 1em;
  margin: 5px auto;
  font-weight: 600;
  z-index: 1000;
}
.inputContainer p.light {
  color: #828282;
}
.inputContainer p.dark {
  color: #EEE;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@import './fonts/fonts.css';

:root {
  --color-light-blue: #6caab8;
  --color-dark-lila: #171F49;
  --color-light-lila: #4B4C7A;
}

body {
  margin: 0;
  padding: 0;
  background-color: #FFF;
  font-family: Myriad-Pro-SemiCond;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  scrollbar-width: thin;
  scrollbar-color: var(--color-light-lila) var(--color-dark-lila);

  overflow-x: hidden;
  overflow-y: auto;
}

/* width and height */
body::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: var(--color-dark-lila);
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: var(--color-light-lila);
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: var(--color-light-blue);
}

body #root {
  width: 100vw;
  min-height: 100vh;

  margin: auto;

  display: flex;
  flex-flow: column;
}


.selctCont {
  position: relative;
  width: 45%;

  margin: 10px;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.selctCont .selected {
  width: 85%;

  margin: auto ;
  padding: 5px calc(5% - 1px);

  border-radius: 10px;
  border: 1px solid #00AFAA;
  outline: none;
  background-color: #FFF;

  text-align: start;
  font-size: 1.3vw;
  color: #006671;
  font-weight: 300;
  font-family: Myriad-Pro-Cond-Italic;

  z-index: 10;

  cursor: pointer;
}

.selctCont .dropCont {
  position: absolute;
  top: 0;
  left: 5%;

  width: 30%;

  margin: auto;
  padding: 5%;

  background-color: #00AFAA;
  border-radius: 10px;

  z-index: 100;
}

.selctCont .dropCont .option {
  margin: 10px auto;

  color: #FFF;
  font-family: Myriad-Pro-Cond-Italic;

  cursor: pointer;
}

.selctCont .dropCont .option:hover {
  font-family: Myriad-Pro-Bold-Cond;
}

/* ========================================= */

@media (max-width: 1024px) {
  .selctCont .selected {
    width: 85%;
    font-size: 2vw;
  }

  .selctCont .dropCont {
    width: 70%;
  }

  .selctCont .dropCont .option {
    margin: 5px auto;
    font-size: 2vw;
  }
}

@media (max-width: 640px) {
  .selctCont .selected {
    width: 85%;
    font-size: 3vw;
  }

  .selctCont .dropCont {
    width: 70%;
  }

  .selctCont .dropCont .option {
    margin: 5px auto;
    font-size: 3vw;
  }
}

@media (max-width: 480px) {
  .selctCont .selected {
    width: 85%;
    font-size: 3.5vw;
  }

  .selctCont .dropCont {
    width: 70%;
  }

  .selctCont .dropCont .option {
    margin: 5px auto;
    font-size: 3.5vw;
  }
}

.loginContainer {
  width: 100vw;
  height: 100vh;

  display: flex;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/login/bgLogin.png');

  overflow: hidden;
}

.loginContainer .container {
  width: 100%;
  height: 100%;

  display: flex;

  scrollbar-width: thin;
  scrollbar-color: #006671 transparent;

  overflow-x: hidden;
  overflow-y: auto;
}

/* width and height */
.loginContainer .container::-webkit-scrollbar {
  width: 8px; height: 8px;
}

/* Track */
.loginContainer .container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.loginContainer .container::-webkit-scrollbar-thumb {
  background: #006671;
}

/* Handle on hover */
.loginContainer .container::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.loginContainer .soporte {
  position: absolute;
  bottom: 2vw;
  right: 2vw;

  margin: auto;
  padding: 10px 20px;

  text-transform: uppercase;
  text-decoration: none;
  color: #FFF;
  font-family: Myriad-Pro-Bold;
  font-weight: 600;

  background-color: #00AFAA;
  box-shadow: 3px 5px 3px #006671;
  border-radius: 10px;
}

.loginContainer .soporte::before {
  content: "Soporte";
}

/* --------------------------------------------- */

.loginContainer .forms {
  width: 30%;

  margin: auto;
  padding: 2% 4%;

  display: flex;
  flex-flow: column;

  background-color: #FFF7;
  border-radius: 2vw;
  box-shadow: 5px 5px 7px #BBBA;
}

.loginContainer .forms .logos {
  width: 100%;

  margin: 40px auto;

  display: flex;
}

.loginContainer .forms .logos div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.loginContainer .forms .logos .logoIdime {
  width: 11vw;
  height: calc(11vw / 3.5103);
  background-image: url('../../img/cover/logoIdime_1.png');

  margin: auto 5% auto auto;
}

.loginContainer .forms .logos .logoAcreditacion {
  width: 7vw;
  height: calc(7vw / 1.0696);
  background-image: url('../../img/cover/logoOnac.png');

  margin: auto auto auto 5%;
}

.loginContainer .forms .eventName {
  width: 90%;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer .forms .eventName p {
  margin: 5px;
  font-size: 5vw;
  color: #878787;
  font-weight: 800;
  font-family: Myriad-Pro-black-cond;
}

.loginContainer .forms .eventName span {
  font-size: 2vw;
  line-height: 2VW;
  color: #00AFAA;
  font-weight: 600;
  font-family: Myriad-Pro-black-cond;

  width: 80%;

  margin: 5px;
}

/* ------------------------------------ */

.loginContainer .forms .question {
  font-size: 1.5vw;
  color: #ADADAD;
  font-family: Myriad-Pro-Regular;

  margin: 10px auto;
}

.loginContainer .forms .registerRedirect {
  margin: 0px auto 20px auto;
  padding: 5px 12%;

  border: none;
  outline: none;
  border-radius: 200px;
  background-color: #FCC43D;

  color: #FFF;
  font-size: 1.5vw;
  font-family: Myriad-Pro-Bold;

  cursor: pointer;
}

/* ---------------------------------------- */

/* ============================================= */
/* ============================================= */
/* ============================================= */

@media (max-width: 1024px) {
  .loginContainer .forms {
    width: 50%;
    margin: auto;
  }

  .loginContainer .forms .logos .logoIdime {
    width: 17vw;
    height: calc(17vw / 3.5103);
  }

  .loginContainer .forms .logos .logoAcreditacion {
    width: 13vw;
    height: calc(13vw / 1.0696);
  }

  .loginContainer .forms .eventName {
    width: 75%;
  }

  .loginContainer .forms .eventName p {
    font-size: 5.5vw;
  }

  .loginContainer .forms .eventName span {
    font-size: 2.3vw;
    line-height: 2.3VW;
    width: 80%;
  }

  .loginContainer .forms .question {
    display: none;
  }

  .loginContainer .forms .registerRedirect {
    position: absolute;
    top: 5%;
    left: 50%;

    padding: 5px 5%;
    font-size: 2.2vw;

    transform: translate(-50%);
  }

  .loginContainer .soporte {
    font-size: 1.5vw;
  }
}

@media (max-width: 640px) {
  .loginContainer .forms {
    width: 80%;
    margin: auto;
  }

  .loginContainer .forms .logos .logoIdime {
    width: 22vw;
    height: calc(22vw / 3.5103);
  }

  .loginContainer .forms .logos .logoAcreditacion {
    width: 20vw;
    height: calc(20vw / 1.0696);
  }

  .loginContainer .forms .eventName {
    width: 75%;
  }

  .loginContainer .forms .eventName p {
    font-size: 8.5vw;
  }

  .loginContainer .forms .eventName span {
    font-size: 3.5vw;
    line-height: 3.5VW;
    width: 80%;
  }

  .loginContainer .forms .question {
    font-size: 3.1vw;
  }

  .loginContainer .forms .registerRedirect {
    padding: 5px 9%;
    font-size: 3.2vw;
  }

  .loginContainer .soporte {
    font-size: 3.5vw;
  }
  .loginContainer .soporte::before {
    content: "ℹ";
  }
}
.roomContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../img/room/bgRoom.png');
}

.roomContainer .sections {
  position: relative;

  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}

/* width and height */
.roomContainer .sections::-webkit-scrollbar {
  width: 6px; height: 6px;
}

/* Track */
.roomContainer .sections::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
.roomContainer .sections::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}

/* Handle on hover */
.roomContainer .sections::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.roomContainer .sections .hamburgerCont {
  display: none;
}

/* ============================================= */
/* ============================================= */

.roomContainer .sections div[class^="desplegable"] {
  position: relative;

  width: auto;
  height: 100%;

  margin: auto 0 auto 0;
  padding: 0;

  background-color: #00AFAA;

  display: flex;
}

.roomContainer .sections [class^="desplegable"] .menu {
  width: 12vw;

  margin: 10vh auto auto auto;

  display: flex;
  flex-flow: column;
}

.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] {
  width: 90%;

  margin: 5px auto;
  padding: 5%;

  display: flex;

  cursor: pointer;
}

.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] div[class^="icon-"] {
  width: 1.5vw;
  height: 1.5vw;

  min-width: 1.5vw;
  min-height: 1.5vw;

  margin: auto 10px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 95%;
}
.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] .chat {
  background-image: url('../../img/room/chatIcon.png');
}
.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] .agenda {
  background-image: url('../../img/room/agendaIconB.png');
}
.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] .game {
  background-image: url('../../img/room/gameIconB.png');
}
.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] .back {
  background-image: url('../../img/room/backIconB.png');
}
.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] div[class^="icon-close"] {
  background-image: url('../../img/room/logOutIconB.png');
  background-size: 70%;
}

.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] button {
  width: 100%;

  background-color: transparent;
  border: none;
  outline: none;

  color: #FFF;
  font-family: Myriad-Pro-Light;
  font-size: 1.1vw;
  text-align: start;

  cursor: pointer;
}

.roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] button:hover {
  font-family: Myriad-Pro-Semibold-italic;
}

/* ------------------------------------ */

.roomContainer .sections [class^="desplegable"] div[class="btnCont-chat clicked-chat"] {
  background-color: #FFF;
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-agenda clicked-agenda"] {
  background-color: #FFF;
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-game clicked-game"] {
  background-color: #FFF;
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-back clicked-back"] {
  background-color: #FFF;
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-close clicked-close"] {
  background-color: #FFF;
}

.roomContainer .sections [class^="desplegable"] div[class="btnCont-chat clicked-chat"] .chat {
  background-image: url('../../img/room/chatIconV.png');
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-agenda clicked-agenda"] .agenda {
  background-image: url('../../img/room/agendaIconV.png');
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-game clicked-game"] .game {
  background-image: url('../../img/room/gameIconV.png');
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-back clicked-back"] .back {
  background-image: url('../../img/room/backIconV.png');
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-close clicked-close"] div[class^="icon-close"] {
  background-image: url('../../img/room/logOutIconV.png');
  background-size: 70%;
}

.roomContainer .sections [class^="desplegable"] div[class="btnCont-chat clicked-chat"] button {
  color: #00AFAA;
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-agenda clicked-agenda"] button {
  color: #00AFAA;
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-game clicked-game"] button {
  color: #00AFAA;
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-back clicked-back"] button {
  color: #00AFAA;
}
.roomContainer .sections [class^="desplegable"] div[class="btnCont-close clicked-close"] button {
  color: #00AFAA;
}

/* ============================================= */
/* ============================================= */

.roomContainer .sections div[class^="menuOptionCont-open"] {
  position: relative;

  width: 20vw;
  height: 100vh;

  background-color: #FFF;

  margin: auto auto auto 0;

  display: flex;
  flex-flow: column;
}

.roomContainer .sections div[class="menuOptionCont-close"] {
  display: none;
}

.roomContainer .sections div[class^="menuOptionCont"] .title {
  width: 90%;

  margin: auto auto 40px auto;
  padding: 0% 5%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
}

.roomContainer .sections div[class^="menuOptionCont"] .title .iconCloseM {
  width: 1.3vw;
  height: 1.3vw;
  min-width: 1.3vw;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-image: url('../../img/room/backIcinM.png');

  margin: auto 2% auto 0;
}

.roomContainer .sections div[class^="menuOptionCont"] .title h2 {
  font-family: Myriad-Pro-Light;
  font-weight: 100;
  font-size: 1.5vw;
  color: #878787;
  text-transform: capitalize;

  margin: auto auto auto 0px;
}

.roomContainer .sections div[class^="menuOptionCont"] .content {
  width: 90%;
  height: 80%;

  margin: 0px auto auto auto;

  display: flex;
}

.roomContainer .sections div[class^="menuOptionCont"] .content div[class^="agendaCont"] {
  width: 100%;
  height: 100%;

  margin: auto;
}

.roomContainer .sections div[class^="menuOptionCont"] .content div[class^="agendaCont"] p {
  font-size: .9vw;
  color: #878787;
  font-family: Myriad-Pro-Light;

  margin: 10px auto;
  width: 100%;
}

.roomContainer .sections div[class^="menuOptionCont"] .content div[class^="agendaCont"] p span {
  color: #00AFAA;
}

.roomContainer .sections div[class^="menuOptionCont"] .content .agendaCont-chat {
  display: none;
}
.roomContainer .sections div[class^="menuOptionCont"] .content .agendaCont-game {
  display: none;
}

.roomContainer .sections div[class^="menuOptionCont"] .content .agendaCont-agenda {
  display: flex;
  flex-flow: column;
}

/* ============================================= */
/* ============================================= */

.gameCont-game {
  width: 100%;
  max-height: 100%;

  margin: 10px auto;

  display: flex;

  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #00666F transparent;
}
.gameCont-game::-webkit-scrollbar {
  width: 6px; height: 6px;
}
.gameCont-game::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.gameCont-game::-webkit-scrollbar-thumb {
  background: #00666F;
  border-radius: 10px;
}
.gameCont-game::-webkit-scrollbar-thumb:hover {
  background: #00232e;
}

.gameCont-agenda {
  display: none;
}

.gameCont-chat {
  display: none;
}

/* ============================================= */
/* ============================================= */

/* ========== DAILY ================== */

.roomContainer .sections div[class^="stream"] .player .callFrame,
.roomContainer .sections div[class^="stream"] .player .callFrame2 {
  position: relative;
  top: -26px;

  aspect-ratio: 16/9;
}

/* ========== DAILY ================== */

.roomContainer .sections div[class^="stream"] {
  position: relative;

  width: 70%;
  height: 90%;
  min-height: 600px;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.roomContainer .sections div[class="stream little"] {
  width: 60%;
}

.roomContainer .sections div[class^="stream"] .logos {
  width: 86%;

  margin: auto auto 10px auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.roomContainer .sections div[class="stream little"] .logos {
  width: 90%;
}

.roomContainer .sections div[class^="stream"] .logos div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.roomContainer .sections div[class^="stream"] .logos .idime {
  width: 15vw;
  height: calc(15vw / 3.2070);

  background-image: url('../../img/room/logosStream.png');
}

.roomContainer .sections div[class^="stream"] .logos .evName {
  width: 25vw;
  height: calc(25vw / 4.7345);

  background-image: url('../../img/room/eventName.png');
}

/* ---------------------------------------------- */

.roomContainer .sections div[class^="stream"] .player {
  margin: 10px auto auto auto;

  display: flex;
  flex-flow: column;

  overflow: hidden;
  width: calc(60vw - 4px);
  height: calc(60vw / 1.77777);
}

.roomContainer .sections div[class^="stream"] .player .iframeCont {
  width: calc(60vw - 4px);
  height: calc(60vw / 1.77777);

  margin: auto;

  display: flex;

  overflow: hidden;

  border: 2px solid #00AFAA;
}

.roomContainer .sections div[class="stream little"] .player .iframeCont {
  width: calc(55vw - 4px);
  height: calc(55vw / 1.77777);
}

.roomContainer .sections div[class^="stream"] .player iframe {
  width: 100%;
  height: 100%;

  margin: auto;

  border: none;
  outline: none;
}

.roomContainer .sections div[class^="stream"] h2 {
  color: #FFF;
  text-shadow: 2px 2px 10px #555;
  font-size: 1vw;
  width: 87%;
  text-align: center;
  font-weight: 300;
  font-family: Myriad-Pro-Regular;

  background-color: #87878777;

  margin: 5px auto;
  padding: 1%;
}

.roomContainer .sections div[class^="stream"] .player .zoomBtn {
  position: absolute;
  bottom: 0%;
  right: -5%;

  padding: 5px 4%;

  color: #FFF;
  text-decoration: none;

  z-index: 100;

  display: flex;
  align-items: center;
}

.roomContainer .sections div[class^="stream"] .player .zoomBtn .imgZoom {
  width: 2vw;
  height: 2vw;

  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('../../img/room/zoomBtn.png');

  z-index: 10;
}

.roomContainer .sections div[class^="stream"] .player .zoomBtn p {
  position: relative;
  left: -0.5vw;

  margin: auto 0px;
  padding: 5px 1vw;

  background-color: #2D8CFF;
  border-radius: 10px;

  font-size: 1vw;
  color: #FFF;
}


/* ============================================= */
/* ============================================= */
/* ============================================= */
/* ============================================= */
/* ============================================= */
/* ============================================= */
/* ============================================= */
/* ============================================= */
/* ============================================= */


@media (max-width: 1024px) {
  .roomContainer .sections .hamburgerCont {
    position: fixed;
    top: 0;
    left: 0;

    margin: auto;

    display: flex;

    z-index: 4000;
  }

  .roomContainer .sections .hamburgerCont .hamburger {
    width: 3vw;
    height: 3vw;

    margin: 2vw;

    background-image: url('../../img/lobby/hamburgerIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;

    cursor: pointer;
  }

  .roomContainer .sections .hamburgerCont .equis {
    width: 3vw;
    height: 3vw;

    margin: 2vw;

    background-image: url('../../img/lobby/equisIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;

    cursor: pointer;
  }

  .roomContainer .sections div[class="desplegable-close"] {
    display: none;
  }

  .roomContainer .sections div[class="desplegable-open"] {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;

    z-index: 2000;
  }

  .roomContainer .sections [class^="desplegable"] .menu {
    width: 20vw;
  }

  .roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] div[class^="icon-"] {
    width: 2.5vw;
    height: 2.5vw;
    min-width: 2.5vw;
    min-height: 2.5vw;
  }

  .roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] button {
    font-size: 2.1vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] {
    width: 30vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .title .iconCloseM {
    width: 2.5vw;
    height: 2.5vw;
    min-width: 2.5vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .title h2 {
    font-size: 2.5vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .content div[class^="agendaCont"] p {
    font-size: 1.4vw;
  }

  /* -------------------------------------------- */

  .roomContainer .sections div[class^="stream"] {
    width: 90%;
  }

  .roomContainer .sections div[class="stream little"] {
    width: 90%;
  }

  .roomContainer .sections div[class^="stream"] .logos .idime {
    width: 25vw;
    height: calc(25vw / 3.2070);
  }

  .roomContainer .sections div[class^="stream"] .logos .evName {
    width: 35vw;
    height: calc(35vw / 4.7345);
  }

  .roomContainer .sections div[class^="stream"] .player {
    width: calc(85vw - 4px);
    height: calc(85vw / 1.77777);
  }

  .roomContainer .sections div[class^="stream"] .player .iframeCont {
    width: calc(80vw - 4px);
    height: calc(80vw / 1.77777);
  }

  .roomContainer .sections div[class="stream little"] .player .iframeCont {
    width: calc(80vw - 4px);
    height: calc(80vw / 1.77777);
  }

  .roomContainer .sections div[class^="stream"] .player .callFrame,
  .roomContainer .sections div[class^="stream"] .player .callFrame2 {
    position: relative;
    top: -26px;

    aspect-ratio: 16/9;
  }

  .roomContainer .sections div[class^="stream"] h2 {
    font-size: 1.6vw;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn .imgZoom {
    width: 4vw;
    height: 4vw;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn p {
    font-size: 1.8vw;
  }
}

@media (max-width: 768px) {

  .roomContainer .sections [class^="desplegable"] .menu {
    width: 22vw;
  }

  .roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] div[class^="icon-"] {
    width: 3vw;
    height: 3vw;
    min-width: 3vw;
    min-height: 3vw;
  }

  .roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] button {
    font-size: 2.2vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] {
    width: 35vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .title .iconCloseM {
    width: 3vw;
    height: 3vw;
    min-width: 3vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .title h2 {
    font-size: 3vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .content div[class^="agendaCont"] p {
    font-size: 1.7vw;
  }

  /* -------------------------------------------- */

  .roomContainer .sections div[class^="stream"] {
    width: 95%;
  }

  .roomContainer .sections div[class="stream little"] {
    width: 95%;
  }

  .roomContainer .sections div[class^="stream"] .logos .idime {
    width: 25vw;
    height: calc(25vw / 3.2070);
  }

  .roomContainer .sections div[class^="stream"] .logos .evName {
    width: 35vw;
    height: calc(35vw / 4.7345);
  }

  .roomContainer .sections div[class^="stream"] .player .iframeCont {
    width: calc(85vw - 4px);
    height: calc(85vw / 1.77777);
  }

  .roomContainer .sections div[class="stream little"] .player .iframeCont {
    width: calc(85vw - 4px);
    height: calc(85vw / 1.77777);
  }

  .roomContainer .sections div[class^="stream"] h2 {
    font-size: 1.8vw;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn .imgZoom {
    width: 5vw;
    height: 5vw;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn p {
    font-size: 2.1vw;
  }
}

@media (max-width: 640px) {

  .roomContainer .sections .hamburgerCont .hamburger {
    width: 5vw;
    height: 5vw;

    margin: 3vw;
  }

  .roomContainer .sections .hamburgerCont .equis {
    width: 5vw;
    height: 5vw;

    margin: 3vw;
  }

  .roomContainer .sections [class^="desplegable"] .menu {
    width: 26vw;
  }

  .roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] div[class^="icon-"] {
    width: 4vw;
    height: 4vw;
    min-width: 4vw;
    min-height: 4vw;
  }

  .roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] button {
    font-size: 2.7vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] {
    width: 50vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .title .iconCloseM {
    width: 4vw;
    height: 4vw;
    min-width: 4vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .title h2 {
    font-size: 4vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .content div[class^="agendaCont"] p {
    font-size: 2.5vw;
  }

  /* -------------------------------------------- */

  .roomContainer .sections div[class^="stream"] {
    width: 100%;
  }

  .roomContainer .sections div[class="stream little"] {
    width: 100%;
  }

  .roomContainer .sections div[class^="stream"] .logos .idime {
    width: 30vw;
    height: calc(30vw / 3.2070);
  }

  .roomContainer .sections div[class^="stream"] .logos .evName {
    width: 45vw;
    height: calc(45vw / 4.7345);
  }

  .roomContainer .sections div[class^="stream"] .player {
    width: calc(98vw - 4px);
    height: calc(98vw / 1.333333);
  }

  .roomContainer .sections div[class^="stream"] .player .iframeCont {
    width: calc(95vw - 4px);
    height: calc(95vw / 1.77777);
  }

  .roomContainer .sections div[class="stream little"] .player .iframeCont {
    width: calc(95vw - 4px);
    height: calc(95vw / 1.77777);
  }

  .roomContainer .sections div[class^="stream"] h2 {
    font-size: 2.5vw;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn {
    position: absolute;
    bottom: 0%;
    right: 0%;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn .imgZoom {
    width: 8vw;
    height: 8vw;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn p {
    font-size: 4.1vw;
  }
}

@media (max-width: 380px) {

  .roomContainer .sections .hamburgerCont .hamburger {
    width: 7vw;
    height: 7vw;

    margin: 3vw;
  }

  .roomContainer .sections .hamburgerCont .equis {
    width: 7vw;
    height: 7vw;

    margin: 3vw;
  }

  .roomContainer .sections [class^="desplegable"] .menu {
    width: 40vw;
  }

  .roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] div[class^="icon-"] {
    width: 5.5vw;
    height: 5.5vw;
    min-width: 5.5vw;
    min-height: 5.5vw;
  }

  .roomContainer .sections [class^="desplegable"] div[class^="btnCont-"] button {
    font-size: 3.5vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] {
    width: 60vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .title .iconCloseM {
    width: 5vw;
    height: 5vw;
    min-width: 5vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .title h2 {
    font-size: 5vw;
  }

  .roomContainer .sections div[class^="menuOptionCont"] .content div[class^="agendaCont"] p {
    font-size: 3.3vw;
  }

  /* -------------------------------------------- */

  .roomContainer .sections div[class^="stream"] {
    width: 100%;
  }

  .roomContainer .sections div[class="stream little"] {
    width: 100%;
  }

  .roomContainer .sections div[class^="stream"] .logos .idime {
    width: 35vw;
    height: calc(35vw / 3.2070);
  }

  .roomContainer .sections div[class^="stream"] .logos .evName {
    width: 50vw;
    height: calc(50vw / 4.7345);
  }

  .roomContainer .sections div[class^="stream"] .player .iframeCont {
    width: calc(95vw - 4px);
    height: calc(95vw / 1.77777);
  }

  .roomContainer .sections div[class="stream little"] .player .iframeCont {
    width: calc(95vw - 4px);
    height: calc(95vw / 1.77777);
  }

  .roomContainer .sections div[class^="stream"] h2 {
    font-size: 3.2vw;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn {
    position: absolute;
    bottom: 0%;
    right: 0%;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn .imgZoom {
    width: 10vw;
    height: 10vw;
  }

  .roomContainer .sections div[class^="stream"] .player .zoomBtn p {
    font-size: 4.5vw;
  }
}

@media (max-height: 480px) {

  .roomContainer .sections .hamburgerCont .hamburger {
    width: 4vw;
    height: 4vw;

    margin: 1vw;
  }

  .roomContainer .sections .hamburgerCont .equis {
    width: 4vw;
    height: 4vw;

    margin: 1vw;
  }
}